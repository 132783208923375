import SceneContent from "../editor/content/scene-content";
import {useEffect, useRef, useState} from "react";
import {getScenes, Scene} from "../../services/scene-service";
import {getMarkers} from "../../services/marker-service";
import {
    LeftOutlined,
    RightOutlined,
    LineOutlined,
    ExpandAltOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined, HomeOutlined
} from '@ant-design/icons';
import {Card, Col, ConfigProvider, FloatButton, List, Modal, Row, Select} from "antd";
import Meta from "antd/es/card/Meta";
import '../arrow.css';
import {CatalogueModal} from "../../components/catalogue-modal";
import {VideoPlayer} from "../../components/video-player";
import TextArea from "antd/lib/input/TextArea";
import {useLocation, useNavigate} from "react-router-dom";
import {HomeButton} from "../../components/button/HomeButton";
import PreviewCard from "./preview-card";

const Tour = () => {
    const location = useLocation();
    const locationId = new URLSearchParams(location.search).get('location-id');

    const sceneContentRef = useRef();
    const [scenes, setScenes] = useState([])
    const [listView, setListView] = useState({start: 0, end: 0})
    const [size, setSize] = useState(0);
    const [_scene, setScene] = useState(null);
    const [markers, setMarkers] = useState();
    const [url, setUrl] = useState();
    const [catalogueSelected, setCatalogueSelected] = useState();
    const [video, setVideo] = useState()
    const [showBottomBar, setShowBottomBar] = useState(true);
    const [fullScreen, setFullScreen] = useState(false);
    const [autoRotateSpeed, setAutoRotateSpeed] = useState(0.2);
    const navigate = useNavigate();
    const leftArrowDisabled = () => listView.start <= 0;
    const rightArrowDisabled = () => listView.end >= scenes.length;


    useEffect(() => {
        if (!locationId) navigate('/')
        if (locationId){
            getScenes(locationId).then(
                data => {
                    // setScene(data.data[0])
                    setScene(data.data.sort((a, b) => a.sort - b.sort)[0])
                }
            )
        }

    }, [locationId]);

    useEffect(() => {
        console.log(listView)
    }, [listView]);

    useEffect(() => {
        console.log(size);

        if (size > scenes.length) {
            setListView({
                start: 0,
                end: size
            })
            return;
        }

        if ((scenes.length - listView.start) < size) {
            const start = listView.end - size;
            setListView({
                start: start,
                end: listView.end,
            });
            return;
        }

        setListView({
            start: listView.start,
            end: listView.start + size
        });
    }, [size]);

    useEffect(() => {
        function onFullscreenChange() {
            setFullScreen(Boolean(document.fullscreenElement));
        }

        document.addEventListener('fullscreenchange', onFullscreenChange);

        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);

    useEffect(() => {
        if (!video && !catalogueSelected) {
            console.log('release hold')
            sceneContentRef.current?.setMovingState('goingBackToHorizon');
        }

    }, [video, catalogueSelected])

    const checkBreakpoint = () => {
        const screenWidth = window.innerWidth;
        let currentBreakpoint;

        if (screenWidth < 576) {
            currentBreakpoint = 1;
        } else if (screenWidth < 768) {
            currentBreakpoint = 4;
        } else if (screenWidth < 992) {
            currentBreakpoint = 5;
        } else if (screenWidth < 1200) {
            currentBreakpoint = 6;
        } else {
            currentBreakpoint = 9;
        }

        setSize(currentBreakpoint);
    };

    useEffect(() => {
        // Define a function to check the current breakpoint


        // Attach the event listener to window resize
        window.addEventListener('resize', () => checkBreakpoint());

        // Initial check
        checkBreakpoint();

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', () => checkBreakpoint());
        };
    }, []);

    useEffect(() => {
        if (_scene) {
            const img = _scene.url;
            const idx = img.indexOf('com/');
            const substring = img.substring(idx + 4);
            setUrl(img);

            if (_scene.id) {
                getMarkers(_scene.id).then(
                    (data) => {
                        setMarkers(data.data);
                    }
                )
            }
        }
    }, [_scene]);

    useEffect(() => {
        if (locationId) {
            getScenes(locationId).then(
                (data) => {

                    setScenes(data.data.sort((a, b) => a.sort - b.sort))
                }
            )
        }
    }, [locationId])

    const openUrlInNewTab = (url) => {

        window.open(url, "_blank", "noopener noreferrer");
    }

    const expandContractButton = () => {
        if (showBottomBar)
            return <LineOutlined style={{width: '2vw', cursor: 'pointer'}} onClick={() => setShowBottomBar(false)}/>

        return <ExpandAltOutlined style={{width: '2vw', cursor: 'pointer'}} onClick={() => setShowBottomBar(true)}/>
    }

    const fullScreenButton = () => {
        if (fullScreen)
            return <FullscreenExitOutlined style={{width: '2vw', cursor: 'pointer'}}
                                           onClick={() => document.exitFullscreen()}/>

        return <FullscreenOutlined style={{width: '2vw', cursor: 'pointer'}}
                                   onClick={() => document.body.requestFullscreen()}/>
    }

    const slice = (data) => {
        return data.slice(listView.start, listView.end);
    }

    const calculateAutoRotateSpeed = (speed) => {
        return 0.2 * speed;
    }

    const leftArrowClicked = () => {
        if (leftArrowDisabled()) return;
        setListView({
            ...listView,
            start: listView.start - 1,
            end: listView.end - 1,
        })
        console.log('left');
    }

    const rightArrowClicked = () => {
        if (rightArrowDisabled()) return;
        setListView({
                ...listView,
                start: listView.start + 1,
                end: listView.end + 1,
            }
        )
        console.log('right');
    }


    return <>
        <HomeButton style={{top: -4, right: -8, width: 101, fontSize: '40px', height: 66}}/>

        <div style={{width: '100vw', height: '100vh'}}>
            <SceneContent ref={sceneContentRef} url={url} markers={markers} defaultRotate={true} backToHorizon={true}
                          buttonDisabled={false}
                          autoRotateSpeed={autoRotateSpeed}
                          onRouterClick={(marker) => {
                              const target = scenes.filter((value) => value.id === marker.data.target)[0];
                              setScene(target);
                          }}
                          onCatalogueClick={(marker) => {
                              setCatalogueSelected(marker);
                              sceneContentRef.current?.setMovingState('hold');
                          }}
                          onVideoClick={(marker) => {
                              setVideo(marker.data.videoUrl);
                              sceneContentRef.current?.setMovingState('hold');
                          }}
            />
        </div>
        <div style={{
            zIndex: '1000',
            position: 'absolute',
            bottom: '0',
            left: '0',
            backgroundColor: 'rgba(255,255,255,0.8)',
            width: '100vw'
        }}>
            {showBottomBar && <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px 10px 10px 10px'
            }}>
                <LeftOutlined className={`arrow ${leftArrowDisabled() && 'arrow-disabled'}`}
                              onClick={leftArrowClicked}
                />
                <Row gutter={[16, 0]}>
                    {slice(scenes).map((scene) => {
                        return (<Col>
                            <PreviewCard key={scene.id} scene={scene} active={_scene.id === scene.id} onClick={(scene) => {
                                setScene(scene);
                            }}/>
                        </Col>);
                    })}
                </Row>
                <RightOutlined className={`arrow ${rightArrowDisabled() && 'arrow-disabled'}`}
                               onClick={rightArrowClicked}/>
            </div>}
            <div style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                padding: '5px 10px',
                backgroundColor: 'rgba(150,150,150,0.8)'
            }}>
                {expandContractButton()}
                {fullScreenButton()}
                <Select
                    size={'small'}
                    defaultValue={['1']}
                    style={{width: '100px', padding: '0 10px'}}
                    onChange={(value) => {
                        setAutoRotateSpeed(calculateAutoRotateSpeed(value))
                    }}
                    options={['0.25', '0.5', '1', '1.5', '2', '3'].map(x => ({value: x, label: +x}))}
                />
            </div>
        </div>

        <Modal open={!!catalogueSelected}
               onOk={() => {
                   setCatalogueSelected(undefined)
               }}
               onCancel={() => {
                   setCatalogueSelected(undefined);
               }}
               width={1000}
               footer={null}
        >
            <CatalogueModal title={catalogueSelected?.name}
                            description={catalogueSelected?.data?.description}
                            img={catalogueSelected?.data?.imageUrl}
                            hasVideo={!!catalogueSelected?.data?.videoUrl}
                            onClick={() => {
                                openUrlInNewTab(catalogueSelected?.data?.url);
                            }}
                            onVideoClick={() => {
                                setVideo(catalogueSelected?.data?.videoUrl)
                            }}
            />
        </Modal>

        <Modal open={!!video}
               width={1000}
               footer={null}
               onOk={() => {
                   setVideo(undefined);
               }}
               onCancel={() => {
                   setVideo(undefined);
               }}
               bodyStyle={{padding: '30px 0 0 0'}}
        >
            <VideoPlayer url={video}/>
        </Modal>

    </>
}

export default Tour;